import React from 'react';

import Layout from '../components/Layout';
import * as styles from '../styles/pages/resources.module.css';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Title from '../components/Title/Title';
import Button from '../components/Button/Button';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';

import heroImg from '../images/resources/hero.png';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Resources | Loqtorzi Patient website</title>
      <meta name='description'content='Find educational resources, treatment guides, and information about NPC diagnosis. Learn more including Indications and Important Safety information about Loqtorzi'/>
    </>
  )
}

export default function Resources() {
  return (
    <Layout>
      <Hero image={ { src: heroImg, alt:'Hero Resources' } }>
        <Title>
          <h1>Resources</h1>
          <h3 className='mt-[1.3rem] tablet:mt-[1.8rem]'>Here are resources to help support you on your treatment journey</h3>
        </Title>
      </Hero>

      <GlobalWrapper className={'mobileFull'}>
        <section className='box-shadow mt-[4.2rem] tablet:mt-[2.9rem] tablet:pl-[3.8rem] tablet:py-[3.2rem]'>
          <Title>
            <h2 className='uppercase'><div>Connect with <br className='tablet:hidden'/>LOQTORZI<sup>&reg;</sup></div></h2>
          </Title>
          <div className='mt-[2.1rem] tablet:mt-[2.3rem]'>Join our community for people living with NPC to receive emails about educational tools and resources.</div>
          <div className={ styles.buttonList }>
            <Button type='link' url='/stay-in-touch/'>Stay connected</Button>
          </div>
        </section>

        <section className='box-shadow mt-[4.9rem] tablet:mt-[4.5rem] tablet:pl-[3.8rem] tablet:py-[3.2rem]'>
          <Title>
            <h2 className='uppercase'>Get to know LOQTORZI</h2>
          </Title>
          <div className='mt-[2.1rem] tablet:mt-[2.3rem]'>This brochure contains information to guide you as you start treatment with LOQTORZI.</div>
          <div className={ styles.buttonList }>
            <Button type='pdf' url='/pdf/patient-brochure.pdf' target='_blank'>Download</Button>
            <Button type='pdf' url='/pdf/patient-brochure-mandarin.pdf' target='_blank'><span>Download <span className={ styles.button__subtext }>(Mandarin Version)</span></span></Button>
            <Button type='pdf' url='/pdf/patient-brochure-spanish.pdf' target='_blank'><span>Download <span className={ styles.button__subtext }>(Spanish Version)</span></span></Button>
          </div>
        </section>


        <section className='box-shadow mt-[4.9rem] tablet:mt-[4.5rem] tablet:pl-[3.8rem] tablet:py-[3.2rem]'>
          <Title>
            <h2 className='uppercase'>TALK TO YOUR DOCTOR ABOUT LOQTORZI</h2>
          </Title>
          <div className='mt-[2.1rem] tablet:mt-[2.3rem]'>This handy discussion guide provides useful questions to ask your healthcare providers.</div>
          <div className={ styles.buttonList }>
            <Button type='pdf' url='/pdf/loqtorzi-doctor-discussion-guide.pdf' target='_blank'>Download</Button>
            <Button type='pdf' url='/pdf/loqtorzi-doctor-discussion-guide-mandarin.pdf' target='_blank'><span>Download <span className={ styles.button__subtext }>(Mandarin Version)</span></span></Button>
            <Button type='pdf' url='/pdf/loqtorzi-doctor-discussion-guide-spanish.pdf' target='_blank'><span>Download <span className={ styles.button__subtext }>(Spanish Version)</span></span></Button>
          </div>
        </section>

      </GlobalWrapper>

      <GlobalWrapper className={'desktop:pb-[4.5rem]'}>
        <ColumnsConnections className={'mt-[2rem] tablet:mt-[6rem]'} data={[
            {
              title: 'Stay connected',
              link: '/stay-in-touch/',
              text: 'Keep yourself updated and informed by joining our community for people living with NPC.',
              className: 'is-active'
            },
            {
              title: 'See side effects',
              link: '/loqtorzi-side-effects/',
              text: 'Learn about the possible side effects of LOQTORZI treatment.'
            },
            {
              title: 'Financial resources',
              link: '/loqtorzi-solutions/',
              text: 'We want to provide you with resources that may help give you access and support for treatment.'
            }
          ]}/>
      </GlobalWrapper>
    </Layout>
  );
}
